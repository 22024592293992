import { initClient, ClientConfig } from '@sunrise/client'
import { defineAsyncComponent } from 'vue'
import { initHotReload } from './hotReload'

const dynamicImports = {
    HydratedBenefits: defineAsyncComponent(
        () => import('./cmsElements/M/M_Benefits_Group/HydratedBenefits/HydratedBenefits.vue'),
    ),
    HydratedNewsTabs: defineAsyncComponent(
        () => import('./cmsElements/M/M_NewsTabs/HydratedNewsTabs/HydratedNewsTabs.vue'),
    ),
    HydratedChannelStage: defineAsyncComponent(
        () => import('./cmsElements/M/M_Channel_Stage/HydratedChannelStage/HydratedChannelStage.vue'),
    ),
    HydratedReportForm: defineAsyncComponent(
        () => import('./cmsElements/E/E_DSA_Report_Form/HydratedReportForm/HydratedReportForm.vue'),
    ),
}

initClient({
    adsEnabled: true,
    dynamicImports,
} as ClientConfig)

initHotReload()
